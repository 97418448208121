/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateVisitReservationSchema } from '../models/CreateVisitReservationSchema';
import type { ReservationCompanySchema } from '../models/ReservationCompanySchema';
import type { ReservationServiceSchema } from '../models/ReservationServiceSchema';
import type { ReservationUserSchema } from '../models/ReservationUserSchema';
import type { ServiceGroupSchema } from '../models/ServiceGroupSchema';
import type { TimeSlotOverviewSchema } from '../models/TimeSlotOverviewSchema';
import type { TimeSlotSchema } from '../models/TimeSlotSchema';
import type { VisitReservationSchema } from '../models/VisitReservationSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReservationsApi {
    /**
     * Create Visit Reservation
     * Create visit reservation for selected physiotherapeutist and service in given time slot.
     * @returns VisitReservationSchema Created
     * @throws ApiError
     */
    public static reservationsCreateVisitReservationPost({
        requestBody,
    }: {
        requestBody: CreateVisitReservationSchema,
    }): CancelablePromise<VisitReservationSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/reservations/visit-reservation/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Visit Reservation
     * Delete visit reservation.
     * @returns void
     * @throws ApiError
     */
    public static reservationsDeleteVisitReservationDelete({
        visitId,
    }: {
        visitId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/reservations/visit-reservation/',
            query: {
                'visit_id': visitId,
            },
        });
    }
    /**
     * Get Visit Reservation
     * Get visit reservation.
     * @returns VisitReservationSchema OK
     * @throws ApiError
     */
    public static reservationsGetVisitReservationGet({
        visitId,
    }: {
        visitId: string,
    }): CancelablePromise<VisitReservationSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/visit-reservation/',
            query: {
                'visit_id': visitId,
            },
        });
    }
    /**
     * Get Company
     * Get company public information
     * @returns ReservationCompanySchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyGet({
        companyId,
    }: {
        companyId: string,
    }): CancelablePromise<ReservationCompanySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}',
            path: {
                'company_id': companyId,
            },
        });
    }
    /**
     * Get Company Based On Slug
     * Get company public information
     * @returns ReservationCompanySchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyBasedOnSlugGet({
        slug,
    }: {
        slug: string,
    }): CancelablePromise<ReservationCompanySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/slug/{slug}',
            path: {
                'slug': slug,
            },
        });
    }
    /**
     * Get Company Service Groups
     * Get company reservable service groups ordered by service group name.
     * @returns ServiceGroupSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyServiceGroupsGet({
        companyId,
    }: {
        companyId: string,
    }): CancelablePromise<Array<ServiceGroupSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/service-groups/',
            path: {
                'company_id': companyId,
            },
        });
    }
    /**
     * Get Company Services
     * Get company reservable services ordered by service name.
     * @returns ReservationServiceSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyServicesGet({
        companyId,
        serviceGroupId,
    }: {
        companyId: string,
        serviceGroupId?: (string | null),
    }): CancelablePromise<Array<ReservationServiceSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/services/',
            path: {
                'company_id': companyId,
            },
            query: {
                'service_group_id': serviceGroupId,
            },
        });
    }
    /**
     * Get Company Time Slots
     * Get free time slots for selected company, physiotherapists, service and date
     * @returns TimeSlotSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyTimeSlotsGet({
        companyId,
        serviceId,
        date,
        userIds,
    }: {
        companyId: string,
        serviceId: string,
        date: string,
        userIds?: Array<string>,
    }): CancelablePromise<Array<TimeSlotSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/open-slots/',
            path: {
                'company_id': companyId,
            },
            query: {
                'service_id': serviceId,
                'date': date,
                'user_ids': userIds,
            },
        });
    }
    /**
     * Get Company Time Slots Overview
     * Get free time slots for selected company, physiotherapists, service and date
     * @returns TimeSlotOverviewSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyTimeSlotsOverviewGet({
        companyId,
        serviceId,
        date,
        userIds,
    }: {
        companyId: string,
        serviceId: string,
        date: string,
        userIds?: Array<string>,
    }): CancelablePromise<Array<TimeSlotOverviewSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/overview/',
            path: {
                'company_id': companyId,
            },
            query: {
                'service_id': serviceId,
                'date': date,
                'user_ids': userIds,
            },
        });
    }
    /**
     * Get Company Users
     * Get company reservable users ordered by last_name, first_name.
     * @returns ReservationUserSchema OK
     * @throws ApiError
     */
    public static reservationsGetCompanyUsersGet({
        companyId,
        serviceId,
    }: {
        companyId: string,
        serviceId?: (string | null),
    }): CancelablePromise<Array<ReservationUserSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/reservations/{company_id}/users/',
            path: {
                'company_id': companyId,
            },
            query: {
                'service_id': serviceId,
            },
        });
    }
}
