import { defineStore } from 'pinia';
import {
  VisitListSchema,
  VisitsApi,
  CreateVisitSchema,
  VisitDetailSchema,
  UpdateVisitSchema,
  VisitFormSchema,
  VisitImageSchema,
  PatchVisitSchema,
  ReceiptReadSchema,
  CheckVisitSchema,
  FTActionVisitPatchSchema,
  VisitReceiptCreateSchema,
  RecurringVisitDeleteStrategyChoices,
  GroupVisitDetailSchema,
  GroupVisitsApi,
  CreateGroupVisitSchema,
  UpdateGroupVisitSchema,
} from 'src/api';
import { Errors } from 'src/utils/apiErrors';
import { Pagination, VisitsGetVisitListGetQueryEnum } from 'components/models';
import { Notify } from 'quasar';
import gettext from '../../utils/gettext';
import { VisitData } from 'components/visits/_composables/useMultiSelectVisit';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getParsedDateTime, timezone } from 'src/utils/dateUtils';

const { $gettext } = gettext;

export interface VisitState {
  errors: Errors;
  pastVisitList: VisitListSchema[];
  futureVisitList: VisitListSchema[];
  waitingVisitList: VisitListSchema[];
  pastVisitPagination: Pagination;
  futureVisitPagination: Pagination;
  waitingVisitPagination: Pagination;
  pastVisitLoading: boolean;
  futureVisitLoading: boolean;
  createVisitLoading: boolean;
  waitingVisitListLoading: boolean;
  visitDetail: VisitDetailSchema | null;
  groupVisitDetail: GroupVisitDetailSchema | null;
  visitForm: VisitFormSchema | null;
  visitImage: VisitImageSchema | null;
  visitPayment: ReceiptReadSchema | null;
  calendarVisitTime: Date | string | null;
  checkVisitCorrectness: boolean;
  fetchVisitLoading: boolean;
  visitCreate: CreateVisitSchema;
  groupVisitCreate: CreateGroupVisitSchema;
  visitUpdate: UpdateVisitSchema;
  groupVisitUpdate: UpdateGroupVisitSchema;
}

export const useVisit = defineStore({
  id: 'useVisit',
  state: (): VisitState => {
    return {
      waitingVisitList: [],
      waitingVisitListLoading: false,
      errors: {},
      pastVisitList: [],
      futureVisitList: [],
      visitDetail: null,
      visitPayment: null,
      pastVisitPagination: {
        rowsPerPage: 10,
        rowsNumber: 0,
        page: 1,
        sortBy: null,
        descending: true,
      },
      futureVisitPagination: {
        rowsPerPage: 10,
        rowsNumber: 0,
        page: 1,
        sortBy: null,
        descending: true,
      },
      waitingVisitPagination: {
        rowsPerPage: 10,
        rowsNumber: 0,
        page: 1,
        sortBy: null,
        descending: true,
      },
      pastVisitLoading: false,
      futureVisitLoading: false,
      createVisitLoading: false,
      visitForm: null,
      visitImage: null,
      calendarVisitTime: null,
      checkVisitCorrectness: true,
      fetchVisitLoading: false,
      groupVisitDetail: null,
      visitUpdate: {
        service_id: '',
        date: new Date().toISOString(),
        duration: 0,
        description: '',
        price: 0,
        patient_not_come: false,
        user_id: '',
        calendar_note: '',
        room_id: '',
        drawing_canvas_side: '',
        drawing_canvas_blank: '',
        drawing_canvas_front: '',
        is_signed: false,
        service_price: 0,
        ft_action_visits: [],
        stock_items: [],
        service_items: [],
      },
      groupVisitUpdate: {
        serviceId: '',
        userId: '',
        roomId: '',
        date: '',
        duration: 0,
        calendarNote: '',
        capacity: 0,
      },
      visitCreate: {
        user_id: '',
        date: '',
        service_id: '',
        patient_id: '',
        duration: 0,
        calendar_note: '',
        room_id: '',
        recurrence_settings: undefined,
      },
      groupVisitCreate: {
        userId: '',
        date: '',
        serviceId: '',
        duration: 0,
        calendarNote: '',
        roomId: '',
        capacity: 0,
      },
    };
  },
  getters: {
    isValid: (state) => {
      return Object.keys(state.errors).length <= 0;
    },
  },
  actions: {
    async getPastVisitList(
      userId: string | undefined = undefined,
      rowsPerPage: number = 10,
      page: number = 1,
      orderBy: string | undefined = undefined,
      paid?: boolean,
      startDate?: Date,
      endDate?: Date,
      searchTerm?: string
    ) {
      this.pastVisitLoading = true;
      this.pastVisitPagination.rowsPerPage = rowsPerPage;
      const offset = (page - 1) * rowsPerPage;
      const requestParams = {
        query: VisitsGetVisitListGetQueryEnum.Past,
        limit: rowsPerPage,
        offset: offset,
        paid: paid ?? undefined,
        startDate: startDate?.toISOString().substring(0, 10),
        endDate: endDate?.toISOString().substring(0, 10),
        searchTerm: searchTerm ?? undefined,
        orderBy: orderBy,
        userId: userId,
      };
      await VisitsApi.visitsGetVisitListGet(requestParams).then((response) => {
        this.pastVisitPagination.rowsNumber = response.count;
        this.pastVisitList = response.items ?? [];
      });
      this.pastVisitLoading = false;
    },

    async getFutureVisitList(
      userId: string | undefined = undefined,
      rowsPerPage: number = 10,
      page: number = 1,
      orderBy: string | undefined = undefined
    ) {
      this.futureVisitLoading = true;
      this.futureVisitPagination.rowsPerPage = rowsPerPage;
      const offset = (page - 1) * rowsPerPage;
      const requestParams = {
        query: VisitsGetVisitListGetQueryEnum.Next,
        limit: rowsPerPage,
        offset: offset,
        orderBy: orderBy,
        userId: userId,
      };
      await VisitsApi.visitsGetVisitListGet(requestParams).then((response) => {
        this.futureVisitPagination.rowsNumber = response.count;
        this.futureVisitList = response.items ?? [];
      });
      this.futureVisitLoading = false;
    },
    async createVisit(createdVisit: CreateVisitSchema) {
      this.createVisitLoading = true;
      await VisitsApi.visitsCreateVisitPost({ requestBody: createdVisit })
        .then((res) => {
          this.visitDetail = res;
          this.checkVisitCorrectness = true;
          this.errors = {};
          Notify.create({
            message: $gettext('Návštěva úspěšně přidána'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              icon: 'fa-solid fa-circle-exclamation',
              color: 'negative',
              timeout: 1000,
            });
          });
        });
      this.createVisitLoading = false;
    },
    async createVisitBatch(visitData: VisitData[]) {
      this.createVisitLoading = true;
      const schemas: CreateVisitSchema[] = visitData.map((data) => {
        const saveDate = zonedTimeToUtc(
          getParsedDateTime(data.date ?? '', data.time ?? ''),
          timezone
        ).toISOString();
        return {
          ...this.visitCreate,
          user_id: data.userId ?? '',
          room_id: data.roomId ?? '',
          date: saveDate,
        };
      });
      let i = 0;
      for (const schema of schemas) {
        await VisitsApi.visitsCreateVisitPost({ requestBody: schema })
          .then((res) => {
            this.visitDetail = res;
            this.checkVisitCorrectness = true;
            this.errors = {};
            Notify.create({
              message: $gettext('Návštěva úspěšně přidána'),
              color: 'positive',
              icon: 'fa-solid fa-circle-check',
              timeout: 1000,
            });
          })
          .catchApiErrors((err) => {
            console.log(err);
            this.errors[`room_id_${i}`] = err.room_id;
            Object.values(err).forEach((e) => {
              Notify.create({
                message: e[0],
                icon: 'fa-solid fa-circle-exclamation',
                color: 'negative',
                timeout: 1000,
              });
            });
          });
        i++;
      }
      this.createVisitLoading = false;
    },
    async checkVisitCreation(data: CheckVisitSchema) {
      await VisitsApi.visitsCheckVisitPost({ requestBody: data })
        .then(() => {
          this.checkVisitCorrectness = true;
          this.errors = {};
        })
        .catchApiErrors((err) => {
          this.errors = err;
          this.checkVisitCorrectness = false;
        });
    },
    async updateGroupVisit(
      id: string,
      updateGroupVisitObj: UpdateGroupVisitSchema
    ) {
      await GroupVisitsApi.groupVisitsUpdateGroupVisitPatch({
        groupVisitId: id,
        requestBody: updateGroupVisitObj,
      })
        .then((response) => {
          this.errors = {};
          this.groupVisitDetail = response;
          Notify.create({
            message: $gettext('Uloženo'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Návštěvu se nepodařilo uložit'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 2000,
          });
        });
    },
    async updateVisit(id: string, updateVisitObj: UpdateVisitSchema) {
      await VisitsApi.visitsUpdateVisitPut({
        visitId: id,
        requestBody: updateVisitObj,
      })
        .then((response) => {
          this.errors = {};
          this.checkVisitCorrectness = true;
          this.visitDetail = response;
          Notify.create({
            message: $gettext('Uloženo'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
            timeout: 1000,
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 2000,
            });
          });
        })
        .catch(() => {
          this.errors = { visit_id: ['Návštěvu se nepodařilo uložit!'] };
          Notify.create({
            message: $gettext(
              'Návštěvu se nepodařilo uložit! Zkuste to prosím znovu.'
            ),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 3000,
          });
        });
    },
    async patchVisit(id: string, patchVisitObj: PatchVisitSchema) {
      await VisitsApi.visitsPatchVisitPatch({
        visitId: id,
        requestBody: patchVisitObj,
      })
        .then(() => {
          this.errors = {};
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 1000,
            });
          });
        });
    },
    async getVisit(visitId: string) {
      this.fetchVisitLoading = true;

      await VisitsApi.visitsGetVisitGet({
        visitId: visitId,
      })
        .then((response) => {
          this.visitDetail = response;
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Nepodařilo se načíst návštěvu pacienta'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        })
        .catch((err) => {
          this.errors = err.body;
          Notify.create({
            message: $gettext(
              'Nepodařilo se načíst návštěvu pacienta:\n' + this.errors.detail
            ),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });

      this.fetchVisitLoading = false;
    },
    async createGroupVisit(data: CreateGroupVisitSchema) {
      await GroupVisitsApi.groupVisitsCreateGroupVisitPost({
        requestBody: data,
      })
        .then((response) => {
          this.errors = {};
          this.groupVisitDetail = response;
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Nepodařilo se vytvořit hromadnou návštěvu'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async getGroupVisit(groupVisitId: string) {
      await GroupVisitsApi.groupVisitsDetailGroupVisitGet({
        groupVisitId: groupVisitId,
      })
        .then((response) => {
          this.groupVisitDetail = response;
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Nepodařilo se načíst hromadnou návštěvu'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async addGroupPatient(groupVisitId: string, patientId: string) {
      await GroupVisitsApi.groupVisitsAddPatientToGroupVisitPost({
        groupVisitId: groupVisitId,
        patientId: patientId,
      })
        .then(() => {
          this.errors = {};
          Notify.create({
            message: $gettext('Pacient přídán'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
            });
          });
        });
    },
    async removeGroupPatient(groupVisitId: string, patientId: string) {
      await GroupVisitsApi.groupVisitsRemovePatientFromGroupVisitDelete({
        groupVisitId: groupVisitId,
        patientId: patientId,
      })
        .then(() => {
          this.errors = {};
          Notify.create({
            message: $gettext('Pacient odebrán'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Notify.create({
            message: $gettext('Pacienta se nepodařilo odebrat'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        });
    },
    async deleteGroupVisit(groupVisitId: string) {
      await GroupVisitsApi.groupVisitsDeleteGroupVisitDelete({
        groupVisitId: groupVisitId,
      })
        .then(() => {
          this.errors = {};
          Notify.create({
            message: $gettext('Návštěva smazána'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Object.values(errors).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
            });
          });
        });
    },
    async deleteVisit(
      visitId: string,
      recurringVisitsStrategy?: RecurringVisitDeleteStrategyChoices | null
    ) {
      await VisitsApi.visitsDeleteVisitDelete({
        visitId: visitId,
        recurringVisitsStrategy: recurringVisitsStrategy,
      })
        .then(() => {
          Notify.create({
            message: $gettext('Návštěva smazána'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Object.values(errors).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
            });
          });
        });
    },
    async uploadForm(visitId: string, file: Blob) {
      await VisitsApi.visitsUploadVisitFormPost({
        visitId: visitId,
        formData: { file: file },
      })
        .then((response) => {
          this.visitForm = response;
          Notify.create({
            message: $gettext('Formulář úspěšně nahrán'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
            });
          });
        });
    },
    async deleteForm(formId: number) {
      await VisitsApi.visitsDeleteVisitFormDelete({
        formId: formId,
      })
        .then(() => {
          Notify.create({
            message: $gettext('Formulář smazán'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Object.values(errors).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
            });
          });
        });
    },
    async uploadImage(visitId: string, file: Blob) {
      await VisitsApi.visitsUploadVisitImagePost({
        visitId: visitId,
        formData: { image: file },
      })
        .then((response) => {
          this.visitImage = response;
          Notify.create({
            message: $gettext('Fotografie úspěšně nahrána'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
            });
          });
        });
    },
    async deleteImage(imageId: number) {
      await VisitsApi.visitsDeleteVisitImageDelete({
        imageId: imageId,
      })
        .then(() => {
          Notify.create({
            message: $gettext('Fotografie smazána'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Object.values(errors).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
            });
          });
        });
    },
    async payForVisit(visitId: string, data: VisitReceiptCreateSchema) {
      await VisitsApi.visitsPayForVisitPost({ visitId, requestBody: data })
        .then((response) => {
          this.errors = {};
          this.visitPayment = response[0];
          if (this.visitPayment.is_paid) {
            Notify.create({
              message: $gettext('Návštěva byla úspěšně zaplacena'),
              color: 'positive',
              icon: 'fa-solid fa-circle-check',
            });
          }
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: 'Nepodařilo se zaplatit návštěvu',
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
          });
        });
    },
    async cancelReceipts(visitId: string) {
      await VisitsApi.visitsCancelVisitReceiptsPost({ visitId: visitId })
        .then((response) => {
          this.errors = {};
          this.visitDetail = response;
          Notify.create({
            message: $gettext('Platba za návštěvu byla úspěšně zrušena'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: 'Nepodařilo se zrušit platbu za návštěvu',
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
          });
        });
    },
    async patchFTActionVisit(visitId: string, ftActionVisitId: string) {
      const data: FTActionVisitPatchSchema = {
        isLocked: false,
      };

      await VisitsApi.visitsPatchFtActionVisitPatch({
        visitId: visitId,
        ftActionVisitId: ftActionVisitId,
        requestBody: data,
      })
        .then(() => {
          this.errors = {};
          Notify.create({
            message: $gettext('Výkon je odemknutý'),
            color: 'positive',
            icon: 'fa-solid fa-circle-check',
          });
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: 'Nepodařilo se odemknout výkon',
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
          });
        });
    },
    resetVisitCreate() {
      this.visitCreate = {
        user_id: '',
        date: '',
        service_id: '',
        patient_id: '',
        duration: 0,
        calendar_note: '',
        room_id: '',
        recurrence_settings: undefined,
      };
    },
    /*
      fetches visitDetail data and sets visitUpdate field from visitStore to match data from visitDetail
     */
    async visitUpdateToDefault() {
      if (!this.visitDetail) throw Error('Could not fetch visit');
      // set data
      this.visitUpdate.date = this.visitDetail.date ?? new Date().toISOString();
      this.visitUpdate.description = this.visitDetail.description ?? '';
      this.visitUpdate.duration = this.visitDetail.duration;
      this.visitUpdate.patient_not_come =
        this.visitDetail.patient_not_come ?? false;
      this.visitUpdate.price = Number(this.visitDetail.price) ?? 0;
      this.visitUpdate.service_id = this.visitDetail.service.id ?? '';
      this.visitUpdate.user_id = this.visitDetail.user.id ?? '';
      this.visitUpdate.calendar_note = this.visitDetail.calendar_note ?? '';
      this.visitUpdate.room_id = this.visitDetail.room.id;
      this.visitUpdate.is_signed = this.visitDetail.is_signed;
      this.visitUpdate.drawing_canvas_blank =
        this.visitDetail.drawing_canvas_blank;
      this.visitUpdate.drawing_canvas_side =
        this.visitDetail.drawing_canvas_side;
      this.visitUpdate.drawing_canvas_front =
        this.visitDetail.drawing_canvas_front;
      this.visitUpdate.ft_action_visits = this.visitDetail.ft_action_visits.map(
        (a) => ({
          count: a.count,
          ftTicketId: a.ft_action.ft_ticket.id as string,
          ftActionId: a.ft_action.id as string,
        })
      );
      this.visitUpdate.stock_items = this.visitDetail.stock_items.map(
        (item) => ({
          quantity: item.quantity,
          id: item.id,
          retail_price: Number(item.retail_price) ?? 0,
          stock_item_id: item.stock_item_id,
        })
      );
      this.visitUpdate.service_items = this.visitDetail.service_items.map(
        (item) => ({
          price: Number(item.price),
          service_id: item.service_id,
          id: item.id,
        })
      );
      this.visitUpdate.service_price = this.visitDetail.service_price
        ? Number(this.visitDetail.service_price)
        : undefined;
      this.visitUpdate.recurrence_settings =
        this.visitDetail.recurrence_settings;
      this.visitUpdate.recurring_update_strategy = null;
    },
    async groupVisitUpdateToDefault() {
      if (!this.groupVisitDetail) throw Error('Could not fetch visit');
      // set data
      this.groupVisitUpdate.date =
        this.groupVisitDetail.date ?? new Date().toISOString();
      this.groupVisitUpdate.duration = this.groupVisitDetail.duration;
      this.groupVisitUpdate.serviceId = this.groupVisitDetail.service.id ?? '';
      this.groupVisitUpdate.userId = this.groupVisitDetail.user.id ?? '';
      this.groupVisitUpdate.calendarNote =
        this.groupVisitDetail.calendar_note ?? '';
      this.groupVisitUpdate.roomId = this.groupVisitDetail.room.id;
    },
  },
});
