import { defineStore } from 'pinia';
import { Notify } from 'quasar';
import type {
  ReservationServiceSchema,
  ReservationUserSchema,
  SexOptions,
  TimeSlotOverviewSchema,
  TimeSlotSchema,
  VisitReservationSchema,
  ReservationCompanySchema,
  ServiceGroupSchema,
} from 'src/api';
import { ReservationsApi } from 'src/api';
import { Errors } from 'src/utils/apiErrors';
import gettext from 'src/utils/gettext';

const { $gettext } = gettext;

export interface PatientDetails {
  patientFirstName: string;
  patientLastName: string;
  patientEmail: string;
  patientPhone: string;
  patientSex: SexOptions;
  patientNote: string;
  gdprConsent: boolean;
  receiveNewsletterConsent: boolean;
  vopConsent: boolean;
}

interface ReservationState {
  companyId: string | null;
  company: ReservationCompanySchema | null;
  user: ReservationUserSchema | null;
  service: ReservationServiceSchema | null;
  visitStartTime: Date | null;
  patientData: PatientDetails | null;
  reservation: VisitReservationSchema | null;
  companyUsers: ReservationUserSchema[];
  companyServices: ReservationServiceSchema[];
  companyServiceGroups: ServiceGroupSchema[];
  companyTimeSlots: TimeSlotSchema[];
  companyAvailableDays: TimeSlotOverviewSchema[];
  slotsLoading: boolean;
  anyAvailableUser: boolean;
  errors: Errors;
}

export const useReservations = defineStore({
  id: 'useReservations',
  state: (): ReservationState => {
    return {
      companyId: null,
      service: null,
      user: null,
      visitStartTime: null,
      company: null,
      patientData: null,
      reservation: null,
      companyUsers: [],
      companyServiceGroups: [],
      companyServices: [],
      companyTimeSlots: [],
      companyAvailableDays: [],
      slotsLoading: false,
      anyAvailableUser: false,
      errors: {},
    };
  },
  getters: {
    hasFourthStepAccess(): boolean {
      return !!this.reservation;
    },
    hasThirdStepAccess(): boolean {
      return !!this.service && !!this.user && !!this.visitStartTime;
    },
    hasSecondStepAccess(): boolean {
      return !!this.service;
    },
    getReservationId(): string {
      if (!this.reservation?.id) {
        console.debug('Reservation ID is not set');
        return '';
      }
      return this.reservation.id;
    },
    getCompanyId(): string {
      if (!this.companyId) {
        console.debug('Company ID is not set');
        return '';
      }
      return this.companyId;
    },
    getVisitStartTime(): Date {
      if (!this.visitStartTime) {
        console.debug('Visit start time is not set');
        return new Date(0);
      }
      return this.visitStartTime;
    },
    getServiceId(): string {
      if (!this.service?.id) {
        console.debug('Service ID is not set');
        return '';
      }
      return this.service.id;
    },
    getUserId(): string {
      if (!this.user?.id) {
        console.debug('User ID is not set');
        return '';
      }
      return this.user.id;
    },
    getUserIds(): string[] {
      if (this.user) {
        return [this.user.id ?? ''];
      }
      return this.companyUsers.map((user) => user.id ?? '');
    },
    getUserNameWithDegree(): string {
      if (this.anyAvailableUser) {
        return $gettext('Kdokoliv dostupný');
      }
      return this.user?.degree_full_name ?? '';
    },
  },
  actions: {
    setService(service: ReservationServiceSchema) {
      this.service = service;
    },

    setUser(user?: ReservationUserSchema) {
      this.user = user ?? null;
    },

    setCompanyId(companyId: string) {
      this.companyId = companyId;
    },

    setPatientDetails(patientDetails: PatientDetails) {
      this.patientData = patientDetails;
    },

    setVisitStartTime(visitDate: Date) {
      this.visitStartTime = visitDate;
    },

    async createReservation() {
      if (
        !this.service ||
        !this.user ||
        !this.visitStartTime ||
        !this.companyId ||
        !this.patientData
      ) {
        Notify.create({
          message: $gettext('Některé z povinných údajů chybí'),
          color: 'negative',
          icon: 'fa-solid fa-circle-exclamation',
          timeout: 1000,
        });
        return;
      }
      await ReservationsApi.reservationsCreateVisitReservationPost({
        requestBody: {
          company_id: this.getCompanyId,
          service_id: this.getServiceId,
          user_id: this.getUserId,
          visit_start_time: this.getVisitStartTime.toISOString(),
          patient_email: this.patientData.patientEmail,
          patient_first_name: this.patientData.patientFirstName,
          patient_last_name: this.patientData.patientLastName,
          patient_sex: this.patientData.patientSex,
          patient_phone: this.patientData.patientPhone,
          description: this.patientData.patientNote,
          gdpr_consent: this.patientData.gdprConsent,
          receive_newsletter_consent: this.patientData.receiveNewsletterConsent,
          vop_consent: this.patientData.vopConsent,
        },
      })
        .then((r): void => {
          this.reservation = r;
        })
        .catchApiErrors((errors) => {
          this.reservation = null;
          this.errors = errors;
          Notify.create({
            message: $gettext('Rezervace nemohla být vytvořena'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async fetchCompany(companyId: string) {
      await ReservationsApi.reservationsGetCompanyGet({ companyId: companyId })
        .then((r) => {
          this.company = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst data'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async fetchCompanyUsers(serviceId?: string) {
      await ReservationsApi.reservationsGetCompanyUsersGet({
        companyId: this.getCompanyId,
        serviceId: serviceId,
      })
        .then((r) => {
          this.companyUsers = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst dostupné fyzioterapeuty'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },

    async fetchCompanyServiceGroups() {
      await ReservationsApi.reservationsGetCompanyServiceGroupsGet({
        companyId: this.getCompanyId,
      })
        .then((r) => {
          this.companyServiceGroups = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst skupiny služeb'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },

    async fetchCompanyServices(serviceGroupId?: string) {
      await ReservationsApi.reservationsGetCompanyServicesGet({
        companyId: this.getCompanyId,
        serviceGroupId: serviceGroupId,
      })
        .then((r) => {
          this.companyServices = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst služby'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },

    // fetch available time slots on the selected day
    async fetchCompanyTimeSlots() {
      this.slotsLoading = true;
      const requestParams = {
        companyId: this.getCompanyId,
        serviceId: this.getServiceId,
        date: this.getVisitStartTime.toISOString().substring(0, 10),
        userIds: this.getUserIds,
      };

      await ReservationsApi.reservationsGetCompanyTimeSlotsGet(requestParams)
        .then((r) => {
          this.companyTimeSlots = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst volné termíny'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });

      this.slotsLoading = false;
    },

    // fetch available calendar days
    async fetchCompanyAvailableDays(date: Date) {
      const requestParams = {
        companyId: this.getCompanyId,
        serviceId: this.getServiceId,
        date: date?.toISOString().substring(0, 10),
        userIds: this.getUserIds,
      };

      await ReservationsApi.reservationsGetCompanyTimeSlotsOverviewGet(
        requestParams
      )
        .then((r) => {
          this.companyAvailableDays = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst volné termíny'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async GetVisitReservation(visitId: string) {
      const requestParams = {
        visitId: visitId,
      };
      await ReservationsApi.reservationsGetVisitReservationGet(requestParams)
        .then((r) => {
          this.reservation = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Návštěva nenalezena'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async CancelVisitReservation() {
      if (!this.reservation) {
        Notify.create({
          message: $gettext('Rezervaci se nepodařilo zrušit'),
          color: 'negative',
          icon: 'fa-solid fa-circle-exclamation',
          timeout: 1000,
        });
        return;
      }
      const requestParams = {
        visitId: this.getReservationId,
      };
      await ReservationsApi.reservationsDeleteVisitReservationDelete(
        requestParams
      )
        .then(() => {
          this.errors = {};
          Notify.create({
            message: $gettext('Rezervace byla zrušena'),
            color: 'positive',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Rezervaci se nepodařilo zrušit'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
  },
});
